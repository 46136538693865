import React from 'react'
import Layout from '../components/layout'
import RecommendedPageComponent from "../components/pages/recommended";
import {STORAGE_STRINGS} from "../constants";

const AllRecommendedPage = () => {
  // minimal security to prevent people from seeing this content
  let enabled = false;
  if (typeof window !== 'undefined' && window.localStorage) {
    enabled = window.localStorage.getItem(STORAGE_STRINGS.ENABLE_ALL_RECOMMENDED) === 'true';
  }
  const content = enabled ? <RecommendedPageComponent embedded={false} showAllContent={true} /> : <div>disabled</div>
  if (typeof window !== 'undefined' && !enabled) {
    window.location.replace('/');
  }
  return (
    <Layout embedded={false}>
      {content}
    </Layout>
  )
}

export default AllRecommendedPage
